import { Injectable } from '@angular/core';

import { TransportService } from './transport.service';
import { environment } from '../../local-environment';

@Injectable()
export abstract class BaseApi {
    constructor(public transport: TransportService) {
        this.transport.baseUrl = environment.api.baseUrl;
    }
}
