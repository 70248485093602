import { Component } from '@angular/core';

import { TimeHelper } from '../../../helpers/time.helper';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">&copy; Planika Klub {{ year }}</span>
  `,
})
export class FooterComponent {

  year = TimeHelper.getCurrentYear();

}
