export const ENV_NAME = 'local';

import {environment as env} from './environments/environment';

export const environment = Object.assign(env, {
    api: {
        baseUrl: 'https://api.planika-dev.singularity.is/v1/',
    },
});

