import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseApi } from './base.api';
import { LoginModel } from '../models/login.model';
import { SuccessModel } from '../models/success.model';
import { UserModel } from '../models/user.model';

@Injectable()
export class AuthorizeApi extends BaseApi {
    authorize(model: LoginModel): Observable<SuccessModel<UserModel>> {
        const item = model.isUserLogin ? 'user' : 'admin';
        return this.transport.executePost<SuccessModel<UserModel>>(`authorize/${item}`, model.validValue);
    }
}
