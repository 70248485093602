import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';

import {DataProviderModel} from '../models/data-provider.model';
import {SuccessModel} from '../models/success.model';
import {BusinessModel} from '../models/business.model';
import {CrudApi} from './crud.api';
import {ObservableProvider} from '../common/table.component';
import {TransactionModel} from '../models/transaction.model';
import {AdminModel} from "../models/admin.model";

@Injectable()
export class BusinessApi extends CrudApi<BusinessModel> {

    list(queryParams?: HttpParams): ObservableProvider<BusinessModel> {
        return this.transport.executeGet<DataProviderModel<BusinessModel>>('business/list', queryParams);
    }

    view(id: number): Observable<BusinessModel> {
        return this.transport.executeGet<BusinessModel>(`business/view/${id}`);
    }

    create(item: BusinessModel): Observable<BusinessModel> {
        return this.transport.executePost<BusinessModel>('business/create', item.validValue);
    }

    update(item: BusinessModel) {
        return this.transport.executePut<SuccessModel<any>>(`business/update/${item.id}`, item.validValue);
    }

    delete(item: BusinessModel) {
        return this.transport.executeDelete<SuccessModel<any>>(`business/delete/${item.id}`);
    }

    getItemList() {
        return this.transport.executeGet<{[key: string]: string}>(`business/item-list`);
    }

    getTransactions(item: BusinessModel, queryParams?: HttpParams): ObservableProvider<TransactionModel> {
        return this.transport.executeGet<DataProviderModel<TransactionModel>>(
            `business/transactions/${item.id}`,
            queryParams,
        );
    }

    getAdminList(businessId: number, queryParams?: HttpParams): ObservableProvider<AdminModel> {
        return this.transport.executeGet<DataProviderModel<AdminModel>>(
            `business/${businessId}/admin/list`,
            queryParams,
        );
    }

    createAdmin(businessId: number, model: AdminModel) {
        return this.transport.executePost<ObservableProvider<AdminModel>>(
            `business/${businessId}/admin/create`,
            model.toData,
        );
    }

    updateAdmin(businessId: number, model: AdminModel) {
        return this.transport.executePut<ObservableProvider<AdminModel>>(
            `business/${businessId}/admin/update/${model.id}`,
            model.toData,
        );
    }

    deleteAdmin(businessId: number, model: AdminModel) {
        return this.transport.executeDelete<ObservableProvider<AdminModel>>(
            `business/${businessId}/admin/delete/${model.id}`,
        );
    }
}
