import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

type RequestOptions = {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
};

@Injectable()
export class TransportService {
    baseUrl: string;

    protected defaultHeaders: HttpHeaders;

    constructor(protected http: HttpClient) {}

    setDefaultHeaders(headers: HttpHeaders) {
        this.defaultHeaders = headers;
    }

    protected resolvePath(resource: string) {
        return this.baseUrl + resource;
    }

    protected resolveOptions(options: RequestOptions): RequestOptions {
        if (!options.responseType) {
            options.responseType = 'json';
        }
        return options;
    }

    executePost<T>(resource: string, body?: any | null, query?: HttpParams): Observable<T> {
        return this.http.post<T>(this.resolvePath(resource), body, this.resolveOptions({
            params: query,
        }));
    }
    executePut<T>(resource: string, body?: any | null, query?: HttpParams): Observable<T> {
        return this.http.put<T>(this.resolvePath(resource), body, {
            params: query,
        });
    }

    executeDelete<T>(resource: string, query?: HttpParams): Observable<T> {
        return this.http.delete<T>(this.resolvePath(resource), {
            params: query,
        });
    }

    executeGet<T>(resource: string, query?: HttpParams): Observable<T> {
        return this.http.get<T>(this.resolvePath(resource), {
            params: query,
        });
    }
}
