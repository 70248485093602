import { Component, TemplateRef } from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export type TemplateModalConfig = {
  template: TemplateRef<any>,
  buttons: Array<{
    name: string, 
    click: (confirmCallback: (value: any) => void, event: any) => void, 
    type: "danger" | "warning" | "info" | "success" | "primary",
  }>,
  footerTemplate?: TemplateRef<any>,
  title: string,
  options?: NgbModalOptions,
  data?: any,
};

@Component({
  selector: 'ngx-confirm-modal',
  template: `
    <div class="modal-header">
      <span>{{ config.title }}</span>
    </div>
    <div class="modal-body">
      <ng-container *ngTemplateOutlet="config.template; context: modalContext"></ng-container>
    </div>
    <div class="modal-footer">
      <ng-container *ngTemplateOutlet="config.footerTemplate; context: modalContext"></ng-container>
      <button 
          *ngFor="let item of config.buttons" 
          [attr.class]="'btn btn-md btn-' + item.type" 
          (click)="passItemClick(item, $event)">
          {{ item.name }}
      </button>
    </div>
  `,
})
export class TemplateModalComponent {

  config: TemplateModalConfig;

  constructor(private activeModal: NgbActiveModal) { }

  confirmModal(value: boolean) {
    this.activeModal.close(value);
  }

  passItemClick(item, event: any) {
      item.click((value: any) => this.confirmModal(value), event);
  }

  get modalContext() {
    return {
      $implicit: {
        confirm: (result: any) => this.confirmModal(result),
        data: this.config.data || {},
      },
    };
  }
}
