import { AbstractStorageClient } from './abstract.storage.client';
import { UserModel } from '../../models/user.model';
import { AbstractStorage } from "../storage/abstract.storage";

export class UserStorageClient extends AbstractStorageClient {

    constructor(protected driver: AbstractStorage) {
        super('user.storage', driver);
    }

    get loggedInUser(): UserModel | null {
        const data = this.get('loggedInUser', null);

        if (!data) {
            return null;
        }

        return UserModel.fromData(data);
    }

    saveUser(model?: UserModel) {
        this.set('loggedInUser', model ? model.data : null);
    }
}
