export class PermissionsModel {
    protected manage_business: boolean = false;
    protected manage_all_businesses: boolean = false;
    protected view_admin_info: boolean = false;
    protected can_edit_discount_thresholds: boolean = false;
    protected can_manage_users: boolean = false;

    get canManageBusiness(): boolean {
        return this.manage_business;
    }

    get canManageAllBusiness(): boolean {
        return this.manage_all_businesses;
    }

    get canViewAdminInfo(): boolean {
        return this.view_admin_info;
    }

    get canEditDiscountThresholds(): boolean {
        return this.can_edit_discount_thresholds;
    }

    get canManageUsers(): boolean {
        return this.can_manage_users;
    }

    constructor(data: any = {}) {
        this.manage_business = data.manage_business || false;
        this.manage_all_businesses = data.manage_all_businesses || false;
        this.view_admin_info = data.view_admin_info || false;
        this.can_edit_discount_thresholds = data.can_edit_discount_thresholds || false;
        this.can_manage_users = data.can_manage_users || false;
    }
}
