import {MenuItem} from './menu.item';
import {UserService} from '../services/user.service';

export const items: MenuItem[] = [
    {
        title: 'Kontrolna tabla',
        icon: 'nb-home',
        link: '/portal',
        home: true,
    },
    {
        title: 'Transakcije',
        icon: 'nb-list',
        link: '/portal/transactions',
        verifier: (s: UserService) => s.model.isUser,
    },
    {
        title: 'Prodajna Mjesta',
        icon: 'fa fa-building',
        link: '/portal/business',
        pathMatch: '/portal/business/*',
        verifier: (s: UserService) => s.auth.canManageAllBusiness,
    },
    {
        title: 'Pragovi Boniteta',
        icon: 'nb-grid-a-outline',
        link: '/portal/discount-threshold',
        verifier: (s: UserService) => s.auth.canEditDiscountThresholds,
    },
    {
        title: 'Članovi',
        icon: 'fa fa-users',
        link: '/portal/members',
        pathMatch: '/portal/members/*',
        verifier: (s: UserService) => s.auth.canManageUsers,
    },
    {
        title: 'Profil',
        icon: 'nb-person',
        link: '/portal/profile',
        home: false,
    },
];
