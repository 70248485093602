import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { DiscountThresholdModel } from '../models/discount-threshold.model';
import { DataProviderModel } from '../models/data-provider.model';
import { SuccessModel } from '../models/success.model';
import { CrudApi } from './crud.api';

export type DiscountDataProvider = DataProviderModel<DiscountThresholdModel>;

@Injectable()
export class DiscountApi extends CrudApi<DiscountThresholdModel> {
    list(queryParams?: HttpParams): Observable<DiscountDataProvider> {
        return this.transport.executeGet<DiscountDataProvider>('discount/list', queryParams);
    }

    view(id: number): Observable<DiscountThresholdModel> {
        return this.transport.executeGet<DiscountThresholdModel>(`discount/view/${id}`);
    }

    create(item: DiscountThresholdModel): Observable<DiscountThresholdModel> {
        return this.transport.executePost<DiscountThresholdModel>('discount/create', item.validValue);
    }

    update(item: DiscountThresholdModel) {
        return this.transport.executePut<SuccessModel<any>>(`discount/update/${item.id}`, item.validValue);
    }

    delete(item: DiscountThresholdModel) {
        return this.transport.executeDelete<SuccessModel<any>>(`discount/delete/${item.id}`);
    }
}
