import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseApi } from './base.api';
import { SuccessModel } from '../models/success.model';
import { RequestResetModel } from '../models/request-reset.model';
import { ConfirmResetModel } from '../models/confirm-reset.model';


@Injectable()
export class PasswordApi extends BaseApi {

    requestReset(model: RequestResetModel): Observable<SuccessModel<RequestResetModel>> {
        return this.transport.executePost<SuccessModel<RequestResetModel>>('password/request-reset', model.validValue);
    }


    confirmReset(model: ConfirmResetModel): Observable<SuccessModel<ConfirmResetModel>> {
        return this.transport.executePost<SuccessModel<ConfirmResetModel>>('password/confirm-reset', model.validValue);
    }
}
