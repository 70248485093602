import { Injectable } from '@angular/core';
import { BaseApi } from './base.api';
import { HttpParams } from '@angular/common/http';

import { DataProviderModel } from '../models/data-provider.model';
import { TransactionModel } from '../models/transaction.model';
import { ObservableProvider } from '../common/table.component';

@Injectable()
export class UserApi extends BaseApi {
    getTransactions(queryParams?: HttpParams): ObservableProvider<TransactionModel> {
        return this.transport.executeGet<DataProviderModel<TransactionModel>>('user/transactions', queryParams);
    }

    getBusinessTransactions(queryParams?: HttpParams): ObservableProvider<TransactionModel> {
        return this.transport
            .executeGet<DataProviderModel<TransactionModel>>('user/business-transactions', queryParams);
    }

    getPlatformTransactions(queryParams?: HttpParams): ObservableProvider<TransactionModel> {
        return this.transport
            .executeGet<DataProviderModel<TransactionModel>>('user/platform-transactions', queryParams);
    }
}
