import { AbstractStorage } from '../storage/abstract.storage';

export abstract class AbstractStorageClient {

    protected clientData = {};

    constructor(protected clientName: string, protected driver: AbstractStorage) {
        this.clientData = this.driver.get(this.clientName, {}) || {};
    }

    set(name: string, value: any): void {
        this.clientData[name] = value;
        this.saveData();
    }

    get(name: string, defaultValue?: any): any {
        return this.clientData[name] || defaultValue;
    }

    switchStorageDriver(driver: AbstractStorage) {
        this.driver = driver;
        this.saveData();
    }

    protected saveData() {
        if (this.clientName === null) {
            throw new Error('No client name specified!');
        }


        this.driver.set(this.clientName, this.clientData);
    }
}
