import { Component, OnDestroy } from '@angular/core';
import { delay, withLatestFrom } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {
  NbMediaBreakpoint,
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';

import { items } from '../../menu';
import { StateService } from '../../../services/state.service';
import { MenuItem } from '../../menu.item';
import { UserService } from '../../../services/user.service';
import { NbMenuInternalService } from '@nebular/theme/components/menu/menu.service';

@Component({
  selector: 'ngx-main-layout',
  styleUrls: ['./main.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header position="normal"></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar"
                   tag="menu-sidebar"
                   responsive
                   [end]="sidebar.id === 'end'">
        <nb-sidebar-header>
        <i class="nb-menu"></i>
        </nb-sidebar-header>
        <nb-menu #menuItem [items]="menuItems"></nb-menu>
      </nb-sidebar>

      <nb-layout-column class="main-content">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class MainLayoutComponent implements OnDestroy {

  menuItems: MenuItem[] = [];
  sidebar: any = {};

  protected sidebarState$: Subscription;
  protected menuClick$: Subscription;

  constructor(protected stateService: StateService,
              protected menuService: NbMenuService,
              protected svc: NbMenuInternalService,
              protected themeService: NbThemeService,
              protected bpService: NbMediaBreakpointsService,
              protected userService: UserService,
              protected sidebarService: NbSidebarService) {

      
    this.menuItems = items.filter(i => i.verifier ? i.verifier(this.userService) : true);
    this.svc.resetItems(this.menuItems);

    this.sidebarState$ = this.stateService.onSidebarState()
      .subscribe((sidebar: string) => {
        this.sidebar = sidebar;
      });

    const isBp = this.bpService.getByName('is');

    this.menuClick$ = this.menuService.onItemSelect()
      .pipe(
        withLatestFrom(this.themeService.onMediaQueryChange()),
        delay(20),
      )
      .subscribe(([item, [bpFrom, bpTo]]: [any, [NbMediaBreakpoint, NbMediaBreakpoint]]) => {

        if (bpTo.width <= isBp.width) {
          this.sidebarService.collapse('menu-sidebar');
        }
      });
  }

  ngOnDestroy() {
    this.sidebarState$.unsubscribe();
    this.menuClick$.unsubscribe();
  }
}
