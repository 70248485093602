import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseApi } from './base.api';
import { SuccessModel } from '../models/success.model';
import { RegistrationModel } from '../models/registration.model';

export type RegisterSuccessModel = SuccessModel<RegistrationModel>;

@Injectable()
export class RegistrationApi extends BaseApi {
    register(model: RegistrationModel): Observable<RegisterSuccessModel> {
        return this.transport.executePost<RegisterSuccessModel>('register', model.transformData);
    }

    confirmRegister(token) {
        return this.transport.executePost('register/confirm', {token});
    }
}
