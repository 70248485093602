import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthorizeApi } from './authorize.api';
import { TransportService } from './transport.service';
import { TokenInterceptor } from './token.interceptor';
import { ProfileApi } from './profile.api';
import { RegistrationApi } from './registration.api';
import { PasswordApi } from './password.api';
import { UserApi } from './user.api';
import { DashboardApi } from './dashboard-api';
import { DiscountApi } from './discount.api';
import { MemberApi } from './member.api';
import { BusinessApi } from './business.api';

@NgModule({
  imports: [
     HttpClientModule,
  ],
  providers: [
      AuthorizeApi,
      TransportService,
      RegistrationApi,
      ProfileApi,
      PasswordApi,
      UserApi,
      DashboardApi,
      DiscountApi,
      MemberApi,
      BusinessApi,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true,
      },
  ],
})
export class ApiModule {}
