import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ToasterModule } from 'angular2-toaster';
import { CommonModule } from '@angular/common';

import { ApiModule } from '../api/api.module';
import { UserService } from './user.service';
import { StorageService } from './storage.service';
import { AlertService } from './alert.service';
import { throwIfAlreadyLoaded } from '../@core/module-import-guard';
import { CoreModule } from '../@core/core.module';
import { StateService } from './state.service';
import { DialogBoxService } from './dialog.box.service';
import { MODALS } from './modals';

@NgModule({
    imports: [
        ApiModule,
        ToasterModule,
        CommonModule,
    ],
    providers: [
        UserService,
        StorageService,
        AlertService,
        StateService,
        DialogBoxService,
    ],
    declarations: [
        ...MODALS,
    ],
    entryComponents: [
        ...MODALS,
    ],
    exports: [ToasterModule],
})
export class ServicesModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
 }
