import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from './modals/confirm.modal';
import { TemplateModalComponent, TemplateModalConfig } from './modals/template.modal';


@Injectable()
export class DialogBoxService {

    constructor(private modalService: NgbModal) { }

    public confirm(message: string, title: string = 'Potvrda'): Observable<void> {
        return new Observable((resolver) => {

            const modal = this.modalService.open(ConfirmModalComponent, {
                size: 'sm',
                backdrop: 'static',
                container: 'nb-layout',
                
            });

            modal.componentInstance.title = title;
            modal.componentInstance.message = message;

            modal.result.then(result => resolver.next(result));
        });
    }

    public template(config: TemplateModalConfig): Observable<void> {
        return new Observable((resolver) => {

            const modal = this.modalService.open(TemplateModalComponent, {
                size: 'lg',
                backdrop: 'static',
                container: 'nb-layout',
                ...(config.options || {}),
            });

            modal.componentInstance.config = config;

            modal.result.then(result => resolver.next(result));
        });
    }
}
