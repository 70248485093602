import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserInfo, AdminInfo } from '../models/user.model';
import { ProfileModel } from '../models/profile.model';
import { BaseApi } from './base.api';
import { SuccessModel } from '../models/success.model';

@Injectable()
export class ProfileApi extends BaseApi {

    getInfo(): Observable<UserInfo> {
        return this.transport.executeGet<UserInfo>('profile');
    }

    getAdminInfo(): Observable<AdminInfo> {
        return this.transport.executeGet<AdminInfo>('profile/admin-info');
    }

    saveData(model: ProfileModel): Observable<SuccessModel<ProfileModel>> {
        return this.transport.executePut<SuccessModel<ProfileModel>>('profile/update', model.transformData);
    }
}
