import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseApi } from './base.api';
import { UserStats } from '../models/user-stats.model';
import { PlatformStatsModel } from '../models/platform-stats.model';
import { BusinessStatsModel } from '../models/business-stats.model';

@Injectable()
export class DashboardApi extends BaseApi {

    getStats(): Observable<UserStats> {
        return this.transport.executeGet<UserStats>('dashboard/user-stats');
    }

    getBusinessStats(): Observable<BusinessStatsModel> {
        return this.transport.executeGet<BusinessStatsModel>('dashboard/business-stats');
    }

    getPlatformStats(): Observable<PlatformStatsModel> {
        return this.transport.executeGet<PlatformStatsModel>('dashboard/platform-stats');
    } 
}
