import { AbstractStorage } from './abstract.storage';

export class LocalStorage extends AbstractStorage {

    protected storage = window.localStorage;

    initialize(): void {}

    set(name: string, value: any): void {
        this.storage.setItem(name, JSON.stringify(value));
    }

    get(name: string, defaultValue?: any): any {
        try {
            return JSON.parse(this.storage.getItem(name));
        } catch {
            return defaultValue;
        }
    }
}
