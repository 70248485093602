import * as moment from 'moment';

import { StringHelper } from './string.helper';

export type DateObject = { year: number, month: number, day: number };

export class TimeHelper {
  static readonly REST_DATETIME_FORMAT = 'YYYY-MM-DD\\THH:mm:ss\\Z';
  static readonly REST_DATE_FORMAT = 'YYYY-MM-DD';
  static readonly LOCAL_DATE_FORMAT = 'DD.MM.YYYY';
  static readonly LOCAL_DATETIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';

  static formatStringDate(date: DateObject): string {
    const day = StringHelper.padZero(date.day);
    const month = StringHelper.padZero(date.month);
    return `${date.year}-${month}-${day}`
  }

  static restToObjectDate(date: string): DateObject {
    const item = moment(date, TimeHelper.REST_DATE_FORMAT);

    return {
        year: item.year(),
        month: item.month() + 1,
        day: item.date(),
    };
  }

  static objectToLocalDateString(date: DateObject): string {
    const day = StringHelper.padZero(date.day);
    const month = StringHelper.padZero(date.month);
    return `${day}.${month}.${date.year}`
  }

  static getCurrentYear(): number {
    return (new Date()).getFullYear();
  }

  static toRestDateTime(date: DateObject): string {
    return moment(TimeHelper.objectToLocalDateString(date), TimeHelper.LOCAL_DATE_FORMAT)
          .format(TimeHelper.REST_DATETIME_FORMAT);
  }

  static restToLocalDateTime(dateTime: string): string {
    return moment(dateTime, TimeHelper.REST_DATETIME_FORMAT)
          .format(TimeHelper.LOCAL_DATETIME_FORMAT);
  }

  static restToLocalDate(dateTime: string): string {
    return moment(dateTime, TimeHelper.REST_DATETIME_FORMAT)
          .format(TimeHelper.LOCAL_DATE_FORMAT);
  }
}
