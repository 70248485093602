import { Injectable } from '@angular/core';
import { ToasterService, Toast } from 'angular2-toaster';
import 'style-loader!angular2-toaster/toaster.css';

@Injectable()
export class AlertService {

    private timeout = 4000;

    constructor(public toasterService: ToasterService) { }

    protected popToaster(type: string, body: string, title?: string) {
        const toast: Toast = {
            type: type,
            title: title,
            timeout: this.timeout,
            body: body,
        };
        this.toasterService.popAsync(toast);
    }

    public success(body: string, title: string = '') {
        this.popToaster('success', body, title);
    }

    public error(body: string, title: string = 'Greška') {
        this.popToaster('error', body, title);
    }

    public warning(body: string, title: string = 'Upozorenje') {
        this.popToaster('warning', body, title);
    }

    public info(body: string, title: string = 'Info') {
        this.popToaster('info', body, title);
    }

}
