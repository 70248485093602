import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { ObservableProvider } from '../common/table.component';
import { BaseApi } from './base.api';


@Injectable()
export abstract class CrudApi<T> extends BaseApi {
    abstract list(queryParams?: HttpParams): ObservableProvider<T>;
    abstract view(id: number): Observable<T>;
    abstract create(item: T): Observable<any>;
    abstract update(item: T): Observable<any>;
    abstract delete(item: T): Observable<any>;
}
