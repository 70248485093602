import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { DataProviderModel } from '../models/data-provider.model';
import { MemberModel } from '../models/member.model';
import { ObservableProvider } from '../common/table.component';
import { SuccessModel } from '../models/success.model';
import { CrudApi } from './crud.api';
import { TransactionModel } from "../models/transaction.model";

@Injectable()
export class MemberApi extends CrudApi<MemberModel> {

    list(queryParams?: HttpParams): ObservableProvider<MemberModel> {
        return this.transport.executeGet<DataProviderModel<MemberModel>>('members/list', queryParams);
    }

    view(id: number): Observable<MemberModel> {
        return this.transport.executeGet<MemberModel>(`members/view/${id}`);
    }

    delete(item: MemberModel) {
        return this.transport.executeDelete<SuccessModel<any>>(`members/delete/${item.id}`);
    }

    update(item: MemberModel) {
        return this.transport.executePut<SuccessModel<any>>(`members/update/${item.id}`, item.toData);
    }

    create(item: MemberModel) {
        return this.transport.executePost<SuccessModel<any>>(`members/create`, item.toData);
    }


    getTransactions(item: MemberModel, queryParams?: HttpParams): ObservableProvider<TransactionModel> {
        return this.transport.executeGet<DataProviderModel<TransactionModel>>(
            `members/transactions/${item.id}`,
            queryParams,
        );
    }
}
