import { Component, OnInit, OnDestroy } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UserService } from '../../../services/user.service';
import { filter } from 'rxjs/internal/operators/filter';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  userMenu = [
    {
      title: 'Profil',
      data: {
        resolver: () => {
          this.router.navigate(['portal/profile']);
        },
      },
    },
    {
      title: 'Odjavi se',
      data: {
        resolver: () => {
          const wasUser = this.user.model.isUser;
          this.user.logout();
          this.router.navigate([wasUser ? 'home/login' : 'home/admin']);
        },
      },
    },
  ];

  clickSubscription: Subscription;

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    public user: UserService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.clickSubscription = this.menuService.onItemClick().pipe(
      filter(({ tag: t }) => t === 'user-main-context-menu'),
    ).subscribe(e => this.clickSubscription && e.item.data.resolver());
  }

  ngOnDestroy() {
    this.clickSubscription.unsubscribe();
    this.clickSubscription = null;
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');
    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  get leftTime() {
      const {left_months, left_days} = this.user.model.info;

      if (left_months < 1) {
         return `${left_days} dana`;
      }

      return `${left_months + (left_days > 14 ? 1 : 0)} mjeseci`;
  }
}
