import 'rxjs/add/operator/do';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';

import { UserService } from '../services/user.service';
import { AlertService } from '../services/alert.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        protected user: UserService,
        protected alert: AlertService,
        protected router: Router,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!this.user.isGuest) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.user.model.token}`,
                },
            });
        }

        return next.handle(request).do(() => {},  (err: any) => {
            if (this.user.isGuest) {
                return;
            }

            if ((err instanceof HttpErrorResponse) && err.status === 401) {
                this.alert.error('Vaš login nije validan. Molimo ulogujte se ponovo.');
                this.user.logout();
                this.router.navigate(['home/login']);
            }
        });
    }
}
