import { Injectable, EventEmitter } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';

import { UserModel, UserInfo, AdminInfo } from '../models/user.model';
import { AuthorizeApi } from '../api/authorize.api';
import { LoginModel } from '../models/login.model';
import { PermissionsModel } from '../models/permissions.model';
import { SuccessModel } from '../models/success.model';
import { StorageService } from './storage.service';
import { UserStorageClient } from './storage.clients/user.storage.client';
import { ProfileApi } from '../api/profile.api';

const defaultPermissions = new PermissionsModel();

@Injectable({
    providedIn: 'root',
})
export class UserService {
    public model: UserModel = null;

    public authChanged: EventEmitter<void> = new EventEmitter();

    protected store: UserStorageClient;

    constructor(
        private authorizeApi: AuthorizeApi,
        private profileApi: ProfileApi,
        private storageService: StorageService,
    ) {
        const isPersistentLogin = this.storageService.get('userService.persistentLogin');

        const driverName = isPersistentLogin ? StorageService.LOCAL_DRIVER : StorageService.SESSION_DRIVER;
        this.store = this.storageService.createClient(UserStorageClient, driverName);

        const model: UserModel = this.store.loggedInUser;

        if (model) {
            this.performUserLogin(model);
        }
    }

    public login(model: LoginModel) {
        if (!this.isGuest) {
            this.logout();
        }

        return new Observable<void>(o => {
            this.authorizeApi.authorize(model).subscribe((response: SuccessModel<UserModel>) => {
                const driverName = model.rememberMe ? StorageService.LOCAL_DRIVER : StorageService.SESSION_DRIVER;
                this.storageService.set('userService.persistentLogin', model.rememberMe);

                this.store.switchStorageDriver(this.storageService.createDriver(driverName));

                this.performUserLogin(UserModel.fromData(response.data));
                o.next();
            }, (err: any) => {
                o.error(err.error);
            });
        });
    }

    public logout(): void {
        this.model = null;
        this.store.saveUser(null);
        this.authChanged.emit();
    }

    public refreshInfo() {
        return new Observable<void>(o => {
            const observables: Observable<any>[] = [this.profileApi.getInfo()];

            if (this.auth.canViewAdminInfo) {
                observables.push(this.profileApi.getAdminInfo());
            }

            forkJoin(observables).subscribe(([userInfo, adminInfo]: [UserInfo, AdminInfo]) => {
                this.model.info = userInfo;

                if (adminInfo) {
                    this.model.adminInfo = adminInfo;
                }

                this.store.saveUser(this.model);
                o.next();
            }, err => o.error(err));
        });
    }

    protected performUserLogin(model: UserModel): void {
        this.model = model;
        this.store.saveUser(model);
        this.authChanged.emit();
    }

    get isGuest(): boolean {
        return this.model === null;
    }

    get displayName(): string {
        return this.model.displayName;
    }

    get auth(): PermissionsModel {
        return this.isGuest ? defaultPermissions : this.model.permissions;
    }
}
