import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngx-confirm-modal',
  template: `
    <div class="modal-header">
      <span>{{ title }}</span>
    </div>
    <div class="modal-body">
      {{ message }}
    </div>
    <div class="modal-footer">
      <button class="btn btn-md btn-primary" (click)="confirmModal(true)">Da</button>
      <button class="btn btn-md btn-danger" (click)="confirmModal(false)">Ne</button>
    </div>
  `,
})
export class ConfirmModalComponent {

  title: string;
  message: string;

  constructor(private activeModal: NgbActiveModal) { }

  confirmModal(value: boolean) {
    this.activeModal.close(value);
  }
}
