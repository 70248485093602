import { Injectable } from '@angular/core';

import { LocalStorage } from './storage/local.storage';
import { SessionStorage } from './storage/session.storage';
import { AbstractStorageClient } from './storage.clients/abstract.storage.client';
import { AbstractStorage } from './storage/abstract.storage';

type StorageDriverMap = { [key: string]: new() => AbstractStorage };

@Injectable()
export class StorageService {
    static readonly SPEC_VERSION = '105';

    static readonly SESSION_DRIVER = 'session';
    static readonly LOCAL_DRIVER = 'local';

    protected driver: AbstractStorage;
    protected driverInterfaceName: string;

    constructor() {
        const specVersion = window.localStorage.getItem('specVersion');

        if (specVersion !== StorageService.SPEC_VERSION) {
            window.localStorage.clear();
        }

        window.localStorage.setItem('specVersion', StorageService.SPEC_VERSION);
        this.driverName = window.localStorage.getItem('driver') || StorageService.LOCAL_DRIVER;
    }

    set driverName(name) {
        if (!(name in this.driverMap)) {
            throw new Error(`Driver '${name}' is not implemented or not found!`);
        }

        this.driverInterfaceName = name;
        this.driver = this.createDriver(name);
    }

    get driverInterface(): AbstractStorage {
        return this.driver;
    }

    get driverMap(): StorageDriverMap {
        return {
            'local': LocalStorage,
            'session': SessionStorage,
        };
    }

    set(name: string, value: any): void {
        this.driver.set(name, value);
    }

    get(name: string, defaultValue?: any) {
        return this.driver.get(name, defaultValue);
    }

    createClient<T extends AbstractStorageClient>(StorageClient: new(AbstractStorage) => T, driverName?: string): T {
        let driver = this.driverInterface;

        if (driverName && driverName !== this.driverInterfaceName) {
            driver = this.createDriver(driverName);
        }

        return new StorageClient(driver);
    }

    createDriver(driverName: string) {
        const driver: AbstractStorage = new this.driverMap[driverName]();
        driver.initialize();
        return driver;
    }
}
