import { PermissionsModel } from './permissions.model';

export type UserInfo = {
    first_name: string;
    last_name: string;
    address: string;
    sex: string;
    zip_code: string;
    contact_number: string;
    city: string;
    state: string;
    birth_date: string;
    receive_notifications: boolean;
    card_number: string;
    points: number;
    left_months: number;
    left_days: number;
    email: string;
}

export type AdminInfo = {
    email: string;
    role: string;
    business: Business|null;
}

export type Business = {
    name: string;
    address: string;
    city: string;
    state: string;
}

export class UserModel {

    static readonly TYPE_USER = 'member';
    static readonly TYPE_ADMIN = 'admin';
    static readonly TYPE_SUPERADMIN = 'superadmin';

    public token: string;
    public type: string;
    public permissions: PermissionsModel;

    info: UserInfo;
    adminInfo: AdminInfo;

    get displayName(): string {
        return `${this.info.first_name} ${this.info.last_name}`;
    }

    get data(): any {
        return {
            token: this.token,
            type: this.type,
            info: this.info,
            permissions: this.permissions,
        };
    }

    get isUser() {
        return this.type == UserModel.TYPE_USER;
    }

    get isAdmin() {
        return this.type == UserModel.TYPE_ADMIN;
    }

    get isSuperAdmin() {
        return this.type == UserModel.TYPE_SUPERADMIN;
    }

    static fromData(data: any): UserModel {
        const model = new UserModel();

        model.token = data.token || '';
        model.type = data.type || '';
        model.info = data.info || {};
        model.permissions = new PermissionsModel(data.permissions);

        return model;
    }
}
